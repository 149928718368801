.plans-container {
  text-align: center;
  padding-bottom: 80px;
}

.info-text {
  background-color: #f0ffd4;
  padding: 3px 0;
  padding-top: 15px;
  margin: auto;
  width: fit-content;
  padding-right: 20px;
}

.capital {
  font-size: 20px;
  color: #8bb740;
  font-weight: bold;
}

.info-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60vh;
  text-align: left;
  margin: auto;
  padding-bottom: 20px;
}

.info-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin: auto;
  padding-bottom: 20px;
}

.list-width {
  max-width: 52vh;
}

@media screen and (min-width: 700px) {
  .info-list-desktop {
    max-width: 52vh;
  }

  .user-comments-desktop {
    max-width: 47vh;
  }
}

li::marker {
  color: #8bb740;
}

.access-header {
  color: #8bb740;
  font-weight: bold;
  margin: 10px;
  margin-top: 20px;
}

.subscription-plans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.plans-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 10px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.active-plan {
  margin-bottom: 30px;
  align-items: center;
}

.active-plan-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.active-plan-content>h2,
.active-plan-content>.subscription-card {
  margin-bottom: 30px;
}

.card-container {
  margin: 0 3.6px;
}

.subscription-card {
  border-top: 2px solid rgb(207, 207, 207);
  background-color: white;
}

.subscription-card-no-discount {
  margin-top: 8px;
  padding: 0 11px;
  background-color: white;
}

@media (max-width: 400px) {
  .subscription-card-no-discount {
    padding: 0px;
  }
}

.selected-card {
  background-color: #f0ffd4;
  color: #92b052;
}

.brd-rds10 {
  border-radius: 10px;
}

.board {
  border: 2px solid rgb(207, 207, 207);
}

.discount-container {
  background-color: #b3b3b3;
}

.selected-discount {
  background-color: #91b536;
}

.discount {
  margin: 3px 6px;
  padding: 1px 0px;
  color: white;
  margin-bottom: 5px;
  font-size: 13px;
}

.package {
  margin-top: 20px;
  font-weight: bold;
}

.duration-number {
  font-size: 45px;
  font-weight: bold;
  height: 51px;
}

.monthly-duration {
  margin-bottom: 10px;
  margin-top: 9px;
  font-weight: bold;
  height: 30px;
}

.duration-number-no-discount {
  font-size: 45px;
}

.duration-text {
  font-size: 11px;
  margin-bottom: 16px;
  font-weight: bold;
}

.price {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 6px;
}

.price-per-month {
  font-size: 11px;
  font-weight: bold;
  padding-bottom: 10px;
}

.plan-description {
  font-size: 12px;
}

.no-subscription {
  text-align: center;
  margin: 30px;
}

.user-comments {
  background-color: #e7f6fd;
  border-radius: 20px;
  padding: 20px 14px;
  width: fit-content;
  margin: auto;
}

.comment-header {
  font-size: 20px;
  color: #2e8bad;
  font-weight: bold;
  padding: 28px;
}

.comment,
.client {
  font-size: 13px;
  font-style: italic;
  text-align: left;
  margin-left: 15px;
  padding-right: 15px;
}

.my-plan {
  color: #92b052;
}

.hidden {
  visibility: hidden;
}

.plan-expire-date {
  margin: 20px;
}

.free-trial-btn {
  color: #92b052;
  border: none;
  background: none;
}

.free-trial-btn:hover {
  color: #92b052;
  font-weight: bold;
  cursor: default;
}

.cancel-btn {
  color: grey;
  border: none;
  background: none;
}

.cancel-btn:hover {
  color: rgb(85, 85, 85);
  font-weight: bold;
  cursor: default;
}