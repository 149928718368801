$sleepCalendarCell: 30px;
$lightBlue: rgba(235, 246, 255, 1);

#charts {
  text-align: center;
}

.tab-section {
  text-transform: lowercase !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.css-1wf8b0h-MuiTabs-flexContainer {
  justify-content: space-between !important;
}

.growth-charts {
  height: -webkit-fill-available !important;
}

.chart-section {
  background: #f5fbff;
  margin: 30px 0;
  text-align: center;
}

@media screen and (min-width: 851px) {
  .chart-section {
    margin: 30px auto !important;
    width: 50%;
  }
}

.chart-titles {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}

.chart-titles h6 {
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 15;
  line-height: 27px;
  margin-bottom: 0;
}

.chart {
  height: 454.95px;
}

.tooltip-container p {
  margin: 0;
}

.tooltip-container #tooltip-date {
  color: #87ba3b;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.tooltip-container .tooltip-value {
  font-weight: 600;
  line-height: 18px;
  margin-left: 7px;
}

.tooltip-container .tooltip-text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 4px;
  font-size: 12px;
  color: #323b4b;
}

#growth-title>span:first-child {
  display: block;
}

#birth-info {
  background-color: #effacd;
  padding: 4px 12px;
  border-radius: 10px;
  width: 50%;
  text-align: center;
}

#birth-info p {
  margin: 0;
  font-size: small;
  font-weight: 600;
}

#growth-footer {
  text-align: center;
  color: #87ba3b;
}

.who-data {
  color: black;
  font-size: 0.7rem;
}

#who-data {
  margin-bottom: 30px;
  font-size: 13px;
  text-align: center;
}

#who-data p {
  margin-bottom: 0;
}

#view-more {
  padding: 16px 8px;
  font-size: 17px;
  color: black;
}

#view-more a {
  font-weight: 600;
  font-size: 13px;
  text-decoration: underline;
  color: black;
}

.separator-line {
  width: -webkit-fill-available;
  height: 1px;
}

.icon-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 0 0 30px;
}

/* SLEEP SECTION  */
#sleep #calendar .input-container input,
.react-datepicker-wrapper input {
  border: none;
  background-color: #fff;
}

#sleep-table {
  margin: 30px 0;
  overflow-x: auto;
  border: 1px solid rgba(113, 113, 113, 0.28);
  border-radius: 4px;
}

/* striped colors */
.mantine-10uidol[data-striped]>tbody>tr:nth-of-type(even) {
  background-color: rgba(235, 246, 255, 0.44) !important;
  font-weight: bolder !important;
}

.mantine-10uidol[data-striped]>tbody>tr:nth-of-type(odd) {
  background-color: white !important;
}

.mantine-1qybf08 {
  box-shadow: none;
}

/* cell padding */
.mantine-10uidol>tbody>tr>td {
  height: 40px !important;
  padding: 4px !important;
}

#sleep-section {
  overflow-x: auto;
  border-radius: 4px;
}

.header-cell-name {
  // white-space: wrap !important;
  // word-break: break-word !important;
  // overflow-wrap: break-word !important;
  line-height: normal;
  margin-left: 4px;
}

/* Sleep */
.sleep-header-row,
.sleep-row {
  height: $sleepCalendarCell;
}

.sleep-header-cell-hours,
.sleep-column-cell-dates {
  font-size: 8px;
  min-width: $sleepCalendarCell;
  width: $sleepCalendarCell;
  height: inherit;
  float: left;
  text-align: center;
  color: #717171;
}

.sleep-row-hours {
  display: flex;
  white-space: nowrap;
  height: inherit;
}

.sleep-row-hours .sleep-cell {
  min-width: $sleepCalendarCell;
  width: $sleepCalendarCell !important;
  height: inherit;
  float: left;
  background-color: #fff8d8;
  border: 2px solid $lightBlue;
  border-radius: 3px;
}

.custom-cell {
  position: absolute;
  height: inherit;
}

.sleep-column-cell-dates {
  align-content: center;
}

.sleep-header-cell-hours {
  align-content: end;
}

.sleep-record-div {
  position: absolute;
  height: 26px;
  cursor: pointer;
}

.top-layer-table {
  position: absolute;
  border-color: $lightBlue;
}

.sleep-hover-container p {
  margin: 0;
}

.sleep-hover-container #sleep-hover-date {
  font-family: 'Nunito', sans-serif;
  color: #87ba3b;
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  text-align: left;
}

.sleep-hover-container .sleep-hover-text {
  font-family: 'Nunito', sans-serif;
  line-height: 13px;
  font-size: 12px;
  font-weight: 300;
  color: rgba(50, 59, 75, 1);
  text-align: left;
}

#sleep-hover-wrapper {
  width: 121px;
  position: absolute;
  border-radius: 8px;
  padding: 12px;
  background: white;
  box-shadow: 0px 0px 12px 0px rgba(237, 229, 225, 1);
  text-align: center;
}

@media screen and (min-width: 800px) {
  #sleep-section {
    display: flex;
    justify-content: center;
  }

  #tracker-separator-sleep {
    width: 50% !important;
    margin: auto !important;
  }
}

#calendar {
  position: relative;
  margin: 20px 0 30px 0;
}

.empty-state-text {
  font-family: 'Nunito';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 20px 0;
  color: rgba(198, 77, 89, 1);
}

// FEEDING TABLE
#feeding-table {
  margin: 30px 0;
  overflow-x: auto;
  border-radius: 12px !important;
}

#feeding-table tr td:first-child {
  background-color: rgba(247, 147, 30, 0.08);
  color: rgba(113, 113, 113, 1) !important;
  font-weight: 300 !important;
}

.mantine-augnbd>tbody>tr>td {
  padding: 4px !important;
  height: 30px !important;
}