.flag-icon {
    box-shadow: 0 0 1px gray, 0 0 1px gray, 0 0 1px gray;
}

#simple-select-label {
    display: flex;
}

#simple-select {
    margin: 1px;
    text-align: center;
}

.flag-icon {
    margin-right: 3px;
}

#language-select-box {
    top: 3rem;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.label-wrapper {
    position: absolute;
    top: -10px;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 5px;
    z-index: 1;
}

#form-control-box {
    scale: 1.25;
    position: relative;
    z-index: 0;
}


@media (max-width: 37.5rem) {

    #language-select-box {
        top: 3rem;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .label-wrapper {
        position: absolute;
        top: -20px;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 0 5px;
        z-index: 1;
    }

    #form-control-box {
        width: 100%;
        min-width: 0;
        margin-top: 0;
    }

}