.adminFlexContainerColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-container {
    position: relative;
}

.download-button,
.upload-button,
.reupload-button,
.apply-button {
    margin-bottom: 10px;
    border-radius: 25px;
    height: 35px;
    font-weight: bolder;
    font-size: 14px;
    text-align: center;
    justify-content: center;
    background-color: #87ba3b;
    color: white;
    border: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.download-button {
    position: absolute;
    margin-top: 60%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.download-button,
.upload-button,
.reupload-button,
.apply-button {
    width: 200px;
    margin: 30px 0;
}

.primaryHeaderDownload {
    color: #87ba3b;
    margin-bottom: 25px;
    font-weight: bolder;
    font-size: 18px;
    text-align: center;

    p {
        text-transform: uppercase;
        margin: 0;
    }
}

.primaryHeaderUpload {
    color: #87ba3b;
    margin-bottom: 25px;
    margin-top: 100px;
    font-weight: bolder;
    font-size: 18px;
    text-align: center;

    p {
        text-transform: uppercase;
        margin: 0;
    }
}

.adminFlexContainerCentered {
    justify-content: center;
}

.flag-button-container {
    display: flex;
    gap: 10px;
    margin-bottom: 12px;
}

.flag-button {
    border-radius: 8px;
    padding: 15px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
}

.files-wr {
    padding: 20px;

    label {
        margin-bottom: 20px;
        position: relative;
        display: inline-block;
        color: #666666;
        font-size: 18px;
        font-weight: 400;
        cursor: pointer;
        transition: all .2s;

        &:hover,
        &:active {
            color: #666666;
            border-color: #177cca;

            &:after {
                background-color: #177cca;
            }
        }
    }

    .one-file {
        &~.one-file {
            label {
                display: none;
            }
        }

        &.error {
            border: none;

            .file-name {
                color: #ca4a17;
            }
        }
    }

    .file-info {
        text-align: center;
    }

    .file-item {
        position: relative;
        margin-top: 4px;
        display: flex;
        align-items: center;

        &.hide-btn {
            display: none;
        }
    }

    .file-name {
        font-size: 16px;
        font-style: italic;
        line-height: 26px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
    }

}

.download-instructions-button {
    margin: auto;
    font-size: 16px;
    color: #0000EE;
    text-align: center;
    text-decoration: underline;
    font-size: '16px';
    color: '#0000EE';
    border: 'none';
    text-align: 'center';
    text-decoration: 'underline';
}

@media (max-width: 380px) {
    .download-instructions-button {
        top: 87%;
        left: 50%;
        transform: translateX(-50%);
        width: 160px;
        height: 40px;
        font-size: 10px;
    }
}